@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
    url("../Assets//Fonts/Euclid\ Circular\ A\ Regular.ttf") format("truetype");
  font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root{
  --yellow: #ffe01b;
  --yellow-shadow: #ecd018;
  --meadow: #0FC6A8;
  --meadow-shadow: #0caa8f;
  --purple: #9F84BD;
  --purple-shadow: #846e9e;
  --umber: #65524D;
  --umber-shadow: #4c3e3a;

  --fx-xl: 5rem;
  --fx-600: 1.5rem;
  --fx-500: 1.25rem;
  --fx-400: 1rem;

  --fs1: font-size: clamp(1rem, 2.5vw, 1.1rem);

}

body {
  margin: 0;
  padding: 0;
  font-family: "Euclid", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.app{
  max-width: 2048px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Euclid";
  margin : 0;
  padding: 0;
}
a{
  text-decoration: none;
}

ul,ol{
  list-style: none;
}

.tabsScroll ::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}

.tabsScroll ::-webkit-scrollbar-track {
  background: transparent;
}

.tabsScroll ::-webkit-scrollbar-thumb {
  background: #0caa8f;
  border-radius: 50px;
}

.tabsScroll ::-webkit-scrollbar-thumb:hover {
  background: #0caa8f;
}

.ql-editor{
  min-height: 100px;
}

.mobileMenu{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  .mobileMenuDivs{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
}

.catIcon{
  border-radius: .25rem;
  cursor: default;
  display: flex;
  grid-gap: .5rem;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: .5rem .75rem;
  svg{
    margin: 0;
    padding: 0;
  }
  &:hover{
    background-color: #f8f8f8;
  }
}

.drawerForChat{
  .mantine-Drawer-overlay{
    background: #00000088;
  }
  .mantine-Drawer-drawer{
    margin: 2rem;
    border-radius: 0.5rem;
  }
  .chatWrapper{
    height: 94%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .chatMessages{
      max-height: 500px;
      height: 500px;
      overflow: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      p{
        margin: 0;
        font-size: 15px;
      }

      .teacherMessage{
        margin: 1rem 0;
        margin-right: auto;
        width: 75%;
        background-color: #eee;
        padding: .75rem 1rem;
        border-radius: 0.5rem;
      }
      .teacherMessage:first-child{
        margin: 0;
        margin-right: auto;
      }
      .teacherMessage + .teacherMessage{
        margin: 0.25rem 0;
        margin-right: auto;
      }
      .studentMessage{
        margin: 1rem 0;
        margin-left: auto;
        width: 75%;
        background-color: #d9caec;
        padding: .75rem 1rem;
        border-radius: 0.5rem;
        img{
          display: flex;
          margin: 0 auto;
          max-width: 100%;
          max-height: 200px;
          object-fit: contain;
          background: #eee;
        }
        audio{
          display: flex;
          margin: 1.5rem auto 1rem auto;
          width: 100%;
        }
        video{
          height: 200px;
          max-width: 100%;
        }
      }
      .studentMessage:first-child{
        margin: 0;
        margin-left: auto;
      }
      .studentMessage + .studentMessage{
        margin: 0.25rem 0;
        margin-left: auto;
      }
    }
    form{
      margin-top: 1rem;
      .fileInputs{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: .5rem;
        margin-bottom: .5rem;
        align-items: center;
        justify-content: space-between;

        .fileInput{
          display: none;
        }
        .filePart{
          justify-content: center;
          border: 1px solid #aaa;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0.5rem .75rem;
          cursor: pointer;
          transition: all 0.15s;
          p{
            margin: 0;
            margin-left: 0.25rem;
          }
          &:hover{
            background-color: #eee;
          }
        }
      }
      .filePreview{
        button{
          position: absolute;
          background: none;
          color: #888;
          margin: .5rem;
          right: 5%;
          cursor: pointer;
          z-index: 10;
          border: 1px solid transparent;
          border-radius: 0.25rem;
          &:hover{
            border: 1px solid #888;
          }
        }
      }
      img{
        display: flex;
        margin: 0 auto;
        width: 100%;
        height: 200px;
        object-fit: contain;
        background: #eee;
      }
      audio{
        display: flex;
        margin: 1.5rem auto 1rem auto;
        width: 100%;
      }
      video{
        height: 200px;
        width: 100%;
      }
      textarea{
        display: flex;
        position: relative;
        width: 94%;
        resize: none;
        border: 1px solid #aaa;
        border-radius: 0.25rem;
        padding: .5rem .75rem;
        outline: none;
        font-size: 15px;
      }
      button.send{
        font-size: 15px;
        cursor: pointer;
        width: 100%;
        padding: 0.75rem 0;
        margin-top: 0.5rem;
        border: none;
        background: var(--yellow);
        border-radius: 0.25rem;
        &:hover{
          background: var(--yellow-shadow);
        }
      }
    }
  }
}

.listWrapper{
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  .userListModal{
    button{
      border: none;
      border-radius: .25rem;
      cursor: pointer;
      display: flex;
      grid-gap: 1rem;
      width: 100%;
      align-items: center;
      background: #eee;
      img{
        margin: 0;
        width: 50px;
        height: 50px;
        object-fit: cover;
        transition: all .15s;
        border-radius: 0.5rem;
      }
      h3{
        border-radius: 0 0.25rem 0.25rem 0;
        transition: all .15s;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0;
      }
      &:hover{
        background-color: #ddd;
      }
    }
  }
}

.tsbutton{
  background-color: var(--yellow);
  color: #000;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  &:hover{
    background-color: var(--yellow-shadow);
  }
}

.modal-post-layout {
  @media (max-width: 768px) {
    .mantine-Text-root.mantine-Modal-title.mantine-1ubvgof {
     margin-right: 0px;
    }
    .mantine-t6wy3m{
     border-bottom: none;
    }
  }
  .mantine-Paper-root.mantine-Modal-modal.mantine-rqmd87 {
    width: 60%;
    padding: 40px 37px 23px 35px !important;
    border-radius: 14px;
    @media (max-width: 768px) {
      width: 98%;
      padding: 10px 15px 20px 15px !important;
    }
  }
  .mantine-Select-label {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin : 0px;
    padding: 0px;
    
  }
  .mantine-1j9nzly.mantine-Modal-header {
    padding: 0px 5px 20px 10px;
    border-bottom: none;
    @media (max-width: 768px) {
      padding: 0px 5px 10px 5px;
      border-bottom: none;
    }
  }
  .select-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px; 
    cursor: pointer;



    .select-item-label {
      font-size: 16px;
      font-weight: 400;
      color: #393838;
      display: inline-block;
      margin-bottom: 2px;
      @media (max-width: 768px) {
        font-size: 1px;
      }
    }
  }

  .modal-inner-layout {
    border-radius: 14px;
    border: 1px solid #c3c3c3;
    padding: 22px 20px 23px 22px;
    @media (max-width: 768px) {
      padding: 10px 15px 10px 15px;
      font-size: 12px;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      @media (max-width: 768px) {
        width: 35px;
        height: 35px;
      }
    }

    .post-input {
      width: 100%;
      min-height: 50px;
      margin: 20px 0;
      border: none;
      font-size: 16px;
      resize: none;
      overflow: hidden;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        min-height: 100px !important;
      }
    }
    .media-actions {
      display: flex;
      gap: 1px;
      align-items: center;

      button {
        background: rgba(255, 255, 255, 0.9);
        border: none;
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;

        &:hover {
          background: white;
        }

        @media (max-width: 768px) {
          height: 30px;
        }
      }
    }

    .post-actions {
      width: 100%;
      margin-top: 20px;
      padding-top: 15px; 
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 768px) {
        margin-top: 10px;
        padding-top: 10px;
      }

      .action-btn {
        display: flex;
        gap: 4px;
        background: none;
        border: 1px solid #B8B8B8;
        border-radius: 23px;
        padding: 2px 14px;
        cursor: pointer;


        .active[class*='text']::after {
          content: '';
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
          width: 56%;
          height: 3px;
          background-color: #525252;  // mavi
          border-radius: 2px;
          @media (max-width: 768px) {
            width: 40%;
          }
        }
        // Media tab - mavi
        .active[class*='media']::after {
          content: '';
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
          width: 56%;
          height: 3px;
          background-color: #518EF8;  // mavi
          border-radius: 2px;
          @media (max-width: 768px) {
            width: 40%;
          }
        }
        
        // Poll tab - yaşıl
        .active[class*='poll']::after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 56%;
                    height: 3px;
                    background-color: #029199;  // yaşıl
                    border-radius: 2px;
                    @media (max-width: 768px) {
                      width: 40%;
                    }
                  }
        
                  // Quiz tab - turuncu
        .active[class*='quiz']::after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 56%;
                    height: 3px;
                    background-color: #FE6513;  // turuncu
                    border-radius: 2px;
                    @media (max-width: 768px) {
                      width: 40%;
                    }
                  }

        .tab-item {
          position: relative;
          padding: 8px;
          border-radius: 4px;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            display: block;
            @media (max-width: 768px) {
              width: 14px;
              height: 14px;
            }
          }

          &:hover {
            background-color: #f5f5f5;
          }


        }

        @media (max-width: 768px) {
          padding : 0px 10px;
        }
      }

      .action-divider {
        flex: 1;
      }

      .post-controls {
        button {
          padding: 8px 16px;
          border-radius: 20px;
          border: none;
          font-size: 14px;
          cursor: pointer;
          @media (max-width: 768px) {
            padding: 6px 12px;
            border-radius: 16px;
            font-size: 12px;
          }
        }

        .cancel-btn {
          background: none;
          &:hover {
            background-color: #f5f5f5;
          }
        }

        .post-btn {
          background-color: #ffe01b;
          &:hover {
            background-color: #ecd018;
          }
        }
      }
    }
  }

  .media-preview {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(161px, 1fr));
    gap: 16px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 8px;
    }

    .media-item {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      background: #F8FAFD;
      padding: 24px 3px;

      &.selected{
        border: 2px solid #029199;
      }
      
      img, video {
        width: 100%;
        height: 181px;
        object-fit: fill;
        border-radius: 8px;
        @media (max-width: 768px) {
          height: 120px;
        }
      }


    }
  }

  .quiz-container {
    margin-top: 20px;

    .quiz-question {
      width: 96%;
      min-height: 120px;
      padding: 12px;
      border: 1px solid #E3E3E3;
      border-radius: 8px;
      resize: none;
      font-size: 16px;
      @media (max-width: 768px) {
        min-height: 100px;
        font-size: 14px;
        width: 90%;
      }
      
      &:focus {
        outline: none;
        border-color: #029199;
      }
    }

    .quiz-option {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      input[type="radio"] {
        width: 20px;
        height: 20px;
        margin: 0;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        border: 2px solid #E3E3E3;
        border-radius: 50%;
        position: relative;

        &:checked {
          border-color: #029199;
          
          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background: #029199;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      input[type="text"] {
        flex: 1;
        height: 40px;
        padding: 0 12px;
        border: 1px solid #E3E3E3;
        border-radius: 8px;
        font-size: 16px;

        &:focus {
          outline: none;
          border-color: #029199;
        }
      }

      button {
        background: none;
        border: none;
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:hover {
          background-color: #f5f5f5;
          border-radius: 4px;
        }
      }
    }

    .add-option-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      background: #029199;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
      border: none;

      &:hover {
        background-color: #f5f5f5;
        border-radius: 4px;
      }

      span {
        color: #029199;
      }
    }
  }
}

.apply-modal {
  .mantine-1ijcbem {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  .mantine-1n53jqn {
    color: transparent;
  }
  
  .mantine-1pvv8z0 {
    width: 4px;
    height: 4px;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
  }
  
  .mantine-rqmd87 {
    width: 721px;
    border-radius: 15px;
    padding: 25px 35px;
  }
  
  .mantine-121w2fi.mantine-Modal-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E3E3;
    
    .mantine-Text-root.mantine-Modal-title.mantine-fevmoc {
      font-size: 22px;
      font-weight: 500;
      color: #202939;
    }
  }
}

@media (max-width: 576px){
.mantine-Paper-root.mantine-Modal-modal.mantine-rqmd87{
    padding: 25px 15px !important;
 }
}

.custom-select {
  position: relative;
  width: 200px;
  user-select: none;
  @media (max-width: 768px) {
    width: 50%;
  }

  .select-display {
    padding: 8px 12px;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: white;
    
    &:hover {
      border-color: #029199;
    }

    .selected-item {
      display: flex;
      align-items: center;
      gap: 8px;
      @media (max-width: 768px) {
        span{
          font-size: 13px;
        }
      }

      div{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 20px;
        height: 20px;
        @media (max-width: 768px) {
          width: 14px;
          height: 14px;
        }
      }
    }

    .placeholder {
      color: #757575;
    }

    .arrow {
      transition: transform 0.2s ease;
      stroke: #029199;
      stroke-width: 2;
      
      &.open {
        transform: rotate(180deg);
      }
    }
    @media (max-width: 768px) {
      padding: 4px 8px;
      font-size: 14px;
    } 
  }

  .options-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    max-height: 250px;
    overflow-y: auto;

    .option {
      padding: 8px 12px;
      display: flex;
      gap: 8px;
      cursor: pointer;
      transition: background-color 0.2s;
      @media (max-width: 768px) {
        padding: 8px;
        font-size: 13px;
      }
      div{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 20px;
        height: 20px;
        @media (max-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        background-color: #F5F5F5;
      }

      &.selected {
        background-color: #E3E3E3;
      }
    }
  }
}

